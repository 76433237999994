/* @flow */

import type { TransferProduct } from "state/transfer";
import type { Customer, TransferCustomer } from "shop-state/types";

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { transferCustomer } from "queries";
import { useTransferClient } from "entrypoint/shared";
import useFormat from "helpers/use-format";
import useCustomer from "helpers/use-customer";
import Wrapper from "components/Wrapper";
import LoadingView from "components/LoadingView";
import Help from "./Help";

import styles from "./styles.scss";

type Props = {
  types: $ReadOnlyArray<TransferProduct>,
};

const Landing = ({ types }: Props) => {
  const t = useTranslate();
  const { customer } = useCustomer();
  const client = useTransferClient();
  const [customerData, setCustomerData] = useState<?TransferCustomer>(null);
  const isBasicPointsEligable = types.some(x => x.sasPointsToDepositType === "Basic Points");
  const fallbackText = customer ?
    t("PURCHASE_POINTS.CUSTOMER_NOT_ELIGABLE") :
    t("PURCHASE_POINTS.DESCRIPTION");
  const scrollWidthOffset = el => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = Number.parseInt(styles.headerOffset, 10) * -1;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  useEffect(() => {
    client(transferCustomer).then(response => setCustomerData(response.customer));
  }, []);

  return (
    <div className={styles.block}>
      <Wrapper>
        <header className={styles.header}>
          <h1>{t("PURCHASE_POINTS.TITLE")}</h1>
          {types && types.length > 0 ? <p className={styles.description}>{t("PURCHASE_POINTS.DESCRIPTION")}</p> : null}
        </header>

        <div className={styles.options}>
          {!types &&
            <LoadingView />
          }
          {!isBasicPointsEligable && types.length > 0 &&
          <div>
            <div className={styles.basicPoint}>
              <div className={styles.basicPointTop}>
                <h3>{t("PURCHASE_POINTS.BASIC_POINTS.HEADER")}</h3>
              </div>
              <div className={styles.basicPointBody}>
                <p>{t("PURCHASE_POINTS.BASIC_POINTS.INFO")}</p>
              </div>
              <div className={styles.basicPointBottom}>
                <HashLink
                  smooth
                  scroll={el => scrollWidthOffset(el)}
                  to={`${t("CUSTOMER_SERVICE.LINK")}#buy-and-transfer`}
                >
                  {t("PURCHASE_POINTS.BASIC_POINTS.LINK")}
                </HashLink>
              </div>
            </div>
          </div>
          }
          {types.length > 0 ? types.map(x => {
            const isBasicPoints = x.sasPointsToDepositType === "Basic Points";

            return (
              <div key={x.name}>
                <Link
                  key={x.name}
                  to={"/account/buy-and-transfer-points/" + x.url}
                  className={cn(
                    styles.option,
                    { [styles.option__basic]: x.name === "Buy basic points" }
                  )}
                >
                  <div className={styles.optionTop}>
                    <h3>{x.name}</h3>
                    {isBasicPoints ?
                      <UserTierInfo customer={customer} customerData={customerData} /> :
                      <p>{x.description}</p>
                    }
                  </div>
                  <div className={styles.optionBottom}>
                    <div className={styles.button}>
                      {t("PURCHASE_POINTS.CHOOSE")}
                    </div>
                  </div>
                </Link>
              </div>
            );
          }) : <p>{fallbackText}</p> }
        </div>

        <Help />
      </Wrapper>
    </div>
  );
};

export const UserTierInfo = ({
  customer,
  customerData,
}: {
  customer: ?Customer,
  customerData: ?TransferCustomer,
}): any => {
  const t = useTranslate();
  const { formatBasicPoints } = useFormat();

  if (!customer || !customerData) {
    return null;
  }

  const sasTierMap = [
    {
      level: "S",
      name: t("ACCOUNT.TIER.S"),
      id: 0,
    },
    {
      level: "G",
      name: t("ACCOUNT.TIER.G"),
      id: 1,
    },
    {
      level: "D",
      name: t("ACCOUNT.TIER.D"),
      id: 2,
    },
  ];

  const multipleLevels = customerData.purchaseableIncentiveLevels.length > 1;
  const { sasIncentiveLevel } = customer;
  const { level, pointsRequired, currentTimePeriod } =
    customerData.purchaseableIncentiveLevels.length > 0 ?
      customerData.purchaseableIncentiveLevels[0] : {};
  const tier = sasTierMap.find(x => x.level === level) || sasTierMap[0];
  const getTiersTranslation = () => {
    if (currentTimePeriod) {
      return t("PURCHASE_POINTS.TIERS.EARNED_CURRENT", { points: formatBasicPoints(customerData.basic.earned) });
    }

    return t("PURCHASE_POINTS.TIERS.EARNED_PREVIOUS", { points: formatBasicPoints(customerData.basic.earned) });
  };

  if (multipleLevels) {
    const next = customerData.purchaseableIncentiveLevels.length > 1 ?
      customerData.purchaseableIncentiveLevels[1] : {};
    const nextTier = sasTierMap.find(x => x.level === next.level) || sasTierMap[0];

    return (
      <p>
        {t("PURCHASE_POINTS.TIERS.EARNED_CURRENT", { points: formatBasicPoints(customerData.basic.earned) })}&nbsp;
        {t("PURCHASE_POINTS.TIERS.EXTEND", { points: formatBasicPoints(pointsRequired) })}&nbsp;
        <span className={cn(styles.tier, styles[`level__${level}`])}>{tier.name}</span>
        &nbsp;{t("PURCHASE_POINTS.TIERS.LEVEL")}&nbsp;
        {t("PURCHASE_POINTS.TIERS.UPGRADE", { points: formatBasicPoints(next.pointsRequired) })}&nbsp;
        <span className={cn(styles.tier, styles[`level__${next.level}`])}>{nextTier.name}</span>.
      </p>
    );
  }

  if (customerData.purchaseableIncentiveLevels.length === 0) {
    return null;
  }

  if (sasIncentiveLevel === level) {
    return (
      <p>
        {getTiersTranslation()}&nbsp;
        {t("PURCHASE_POINTS.TIERS.EXTEND", { points: formatBasicPoints(pointsRequired) })}&nbsp;
        <span className={cn(styles.tier, styles[`level__${level}`])}>{tier.name}</span>
        &nbsp;{t("PURCHASE_POINTS.TIERS.LEVEL")}
      </p>
    );
  }

  return (
    <p>
      {getTiersTranslation()}&nbsp;
      {t("PURCHASE_POINTS.TIERS.UPGRADE", { points: formatBasicPoints(pointsRequired) })}&nbsp;
      <span className={cn(styles.tier, styles[`level__${level}`])}>{tier.name}</span>.
    </p>
  );
};

export default Landing;
