/* @flow */

import React from "react";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import Field from "components/Field";

import styles from "./styles.scss";

type Props = {
  type: "billing" | "shipping",
  className?: string,
};

const ContactInfo = ({ type, className }: Props) => {
  const t = useTranslate();

  return (
    <div className={className}>
      <div className={styles.row}>
        {type === "billing" &&
          <div className={styles.s100}>
            <Field name="email" label={t("ADDRESS.EMAIL")} variant="float" />
          </div>
        }
        <div className={cn(styles.s100, { [styles.fullWidth]: type === "shipping" })}>
          <Field name={`${type}.telephone`} label={t("ADDRESS.TELEPHONE")} variant="float" />
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
