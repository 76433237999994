/* @flow */

import type { AdventCalendarDoorToday } from "state/advent-calendar";

import React from "react";
import cn from "classnames";
import { useData } from "crustate/react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "@awardit/react-use-translate";
import { AdventCalendarData, HomeData } from "data";
import Wrapper from "components/Wrapper";
import Button from "components/Button";
import LoadingView from "components/LoadingView";
import ProductList from "components/ProductList";
import RegisterForm from "./RegisterForm";
import { useBreakpoints } from "helpers/use-breakpoints";
import { AdventCalendarDoors } from "@crossroads/ui-components";
import styles from "./styles.scss";

type props = {
  subscribe?: string,
};
type categoryImageProps = {
  clsCatfieldsGeneralImgL: ?{ x1: string, x2: string },
};

const Closed = ({ text }: { text: string }) => <span>{text}</span>;

const Today = ({
  door: { day, category },
}: { door: AdventCalendarDoorToday }) => {
  const t = useTranslate();
  const { getNumberOfDisplayedItems } = useBreakpoints(styles);
  const productsToShow = getNumberOfDisplayedItems({ tiny: 1, small: 2, medium: 3 }, 4);
  const {
    url, name, description, image, products,
  } = category;

  return (
    <div className={styles.today}>
      <div className={styles.todayContainer}>
        <div className={styles.todayContent}>
          <h3>{t("ADVENT_CALENDAR.DOOR_TITLE", { day })}</h3>
          <h2>{name}</h2>
        </div>
      </div>

      <div className={styles.todaySpacer} />

      <Wrapper>
        <div className={styles.todayProductList}>
          <ProductList
            products={products.items.slice(0, productsToShow)}
          />
        </div>
        {products.totalCount > 4 &&
          <Link
            to={{
              pathname: url,
              state: { hint: { type: "category", category: { name, url, image, description } } },
            }}
            className={styles.todayViewAll}
          >
            <Button variant="secondary">
              {t("ADVENT_CALENDAR.VIEW_ALL_PRODUCTS")}
            </Button>
          </Link>
        }
      </Wrapper>
    </div>
  );
};

export const RegisterHero = ({
  fullHeight = false,
  homeView = false,
  categoryImage,
  subscribe,
}: {
  fullHeight?: boolean,
  homeView?: boolean,
  categoryImage?: categoryImageProps | null,
  subscribe?: string,
}) => {
  return (
    <div
      className={cn(styles.landing, { [styles.fullHeight]: fullHeight })}
      style={categoryImage ? { backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.1)), url(${categoryImage.clsCatfieldsGeneralImgL?.x2 || ""})` } : {}}
    >
      <Wrapper>
        <div className={cn(styles.landingContainer, { [styles.homeView]: homeView })}>
          <RegisterForm
            className={styles.registerForm} homeView={homeView} subscriptionStatus={subscribe} />
        </div>
      </Wrapper>
    </div>
  );
};

const AdventCalendarView = ({ subscribe }: props) => {
  const t = useTranslate();
  const data = useData(AdventCalendarData);
  const home = useData(HomeData);
  const categoryImage =
    home.state === "LOADED" &&
    home.data.adventCalendar?.category !== undefined &&
    home.data.adventCalendar.category.clsCatfieldsGeneralImgL !== null ?
      home.data.adventCalendar.category : null;

  if (data.state !== "LOADED") {
    return <LoadingView />;
  }

  let today = null;
  data.data.forEach(day => {
    if (day.__typename === "AdventCalendarDoorToday") {
      today = day;
    }
  });

  const openDays = [];
  data.data.forEach(day => {
    if (day.__typename !== "AdventCalendarDoorLocked") {
      openDays.push(day);
    }
  });

  if (openDays.length === 0) {
    return (
      <div
        className={cn(styles.landing, styles.fullSize)}
        style={categoryImage ? { backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.1)), url(${categoryImage.clsCatfieldsGeneralImgL?.x2 || ""})` } : {}}
      >
        <Wrapper>
          <div className={styles.landingContainer}>
            <RegisterForm className={styles.registerForm} subscriptionStatus={subscribe} />
          </div>
        </Wrapper>
      </div>
    );
  }

  return (
    <div className={styles.block}>
      <Helmet title={t("ADVENT_CALENDAR.TEXT")} />

      <RegisterHero categoryImage={categoryImage} subscribe={subscribe} />

      {(openDays.length > 0 && today) &&
        <Today door={today} />
      }

      <Wrapper>
        <AdventCalendarDoors
          closedSlot={<Closed text={t("ADVENT_CALENDAR.CALENDAR_CLOSED")} />}
          doors={data.data} />
      </Wrapper>
    </div>
  );
};

export default AdventCalendarView;
