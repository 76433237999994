/* @flow */

import type { TransferProduct, Step as StepType } from "state/transfer";
import type { TransferCustomer } from "shop-state/types";

import React, { useState, useContext, useEffect } from "react";
import styles from "./styles.scss";
import { useData, useSendMessage } from "crustate/react";
import { useTranslate } from "@awardit/react-use-translate";
import { getCustomerPoints } from "state/customer";
import Header from "components/AppHeader/StepHeader/buy-and-transfer-header";
import useFormat from "helpers/use-format";
import useCustomer from "helpers/use-customer";
import Wrapper from "components/Wrapper";
import LoadingView from "components/LoadingView";
import PaymentBox from "./PaymentBox";
import FindRecipient from "./FindRecipient";
import PaymentSplit from "./PaymentSplit";
import PointSelect from "./PointSelect";
import InfoIcon from "icons/info.svg";
import { TransferData, OTPData, CmsData } from "data";
import { Tooltip, Foldable, Radio, RadioGroup } from "@crossroads/ui-components";
import { StripePaymentProvider, STRIPE_PAYMENT_METHOD_CODE } from "@crossroads/stripe";
import { StoreInfoContext, useTransferClient } from "entrypoint/shared";
import { confirmStripePaymentIntent, createStripePaymentIntent, transferCustomer } from "queries";
import { setPoints as setTransferPoints, setPointsAmount as setTransferPointsAmount, getSteps, setStep, setRecipient, stepEnabled } from "state/transfer";
import { UserTierInfo } from "components/BuyAndTransferView/Landing";

import boxStyles from "../Box/styles.scss";
import Box from "../Box";
import Step from "./Step";

type Props = {
  pointsProduct: TransferProduct,
};

const PurchasePointsFormView = ({ pointsProduct }: Props) => {
  const t = useTranslate();
  const transfer = useData(TransferData);
  const sendMessage = useSendMessage();
  const transferClient = useTransferClient();
  const storeInfo = useContext(StoreInfoContext);
  const { formatPoints } = useFormat();
  const { customer } = useCustomer();
  const balance = getCustomerPoints(customer);
  const [purchaseFor, setPurchaseFor] = useState(pointsProduct.canBuyForOther.purchasable ? "" : "self");
  const client = useTransferClient();
  const [customerData, setCustomerData] = useState<?TransferCustomer>(null);

  useEffect(() => {
    client(transferCustomer).then(response => setCustomerData(response.customer));
  }, []);

  if (transfer.state === "LOADING") {
    return <LoadingView />;
  }

  // @TODO: validate state based on product type
  const validate = () => {
    const errors = [];
    switch (pointsProduct.url) {
      case "buy-extra-points":
        if (purchaseFor === "" || (purchaseFor !== "self" && !transfer.recipient)) {
          errors.push("No recipient");
        }

        break;
      case "transfer-extra-points":
        if (!transfer.recipient) {
          errors.push("No recipient");
        }

        break;
      default:
        break;
    }

    if (transfer.points === null) {
      errors.push("No selected points payment");
    }

    return errors;
  };

  const { selectedPointPayment, grandTotal } = transfer.data;
  const pointsSplit = selectedPointPayment?.points.selected.incVat || 0;
  const errors = validate();
  const openStep = transfer.step;
  const setOpenStep = (step: StepType) => {
    sendMessage(setStep(step));
  };

  const stepComponents = (step: StepType) => {
    const enabled = stepEnabled(transfer, step, purchaseFor);
    const subTitle = pointsProduct.url === "buy-basic-points" ?
      t("PURCHASE_POINTS.BASIC_POINTS_EARNED", { earned: formatPoints(customerData?.basic.earned || 0) }) :
      (purchaseFor === "self" ?
        t("PURCHASE_POINTS.BALANCE", { balance: formatPoints(balance) }) :
        null);

    let maxValue = 0;
    switch (pointsProduct.url) {
      case "buy-basic-points":
        maxValue = pointsProduct.canBuyForSelf.basicPointsBuyable;
        break;
      case "transfer-extra-points":
        maxValue = Math.min(
          balance,
          pointsProduct.maximumQty * pointsProduct.sasPointsToDeposit
        );
        break;
      default:
        maxValue = pointsProduct.maximumQty * pointsProduct.sasPointsToDeposit;
        break;
    }

    switch (step) {
      case "find-recipient":
        return (
          <>
            {pointsProduct.canBuyForSelf.purchasable === true &&
              <RadioGroup
                className={styles.radioGroup}
                value={purchaseFor}
                onChange={(e: SyntheticEvent<HTMLInputElement>) => {
                  setPurchaseFor(e.currentTarget.value);

                  if (e.currentTarget.value === "friend") {
                    setOpenStep("find-recipient");
                  }
                  else {
                    sendMessage(setRecipient(null));
                    setOpenStep("point-select");
                  }
                }}
              >
                {pointsProduct.url !== "transfer-extra-points" &&
                  <Radio className={boxStyles.block} value="self">
                    {t("PURCHASE_POINTS.BUY_FOR_SELF")}
                  </Radio>
                }

                <Radio className={boxStyles.block} value="friend">
                  {pointsProduct.url !== "transfer-extra-points" ?
                    t("PURCHASE_POINTS.BUY_FOR_FRIEND") :
                    t("PURCHASE_POINTS.TRANSFER_TO_FRIEND")
                  }
                </Radio>
              </RadioGroup>
            }
            {(purchaseFor === "friend" || !pointsProduct.canBuyForSelf.purchasable) &&
              <div className={boxStyles.block}>
                <Step
                  enabled
                  title={t("PURCHASE_POINTS.RECIPIENT")}
                  open={openStep === step}
                  setOpen={() => setOpenStep(step)}
                >
                  <FindRecipient
                    recipient={transfer.recipient}
                    onNext={user => {
                      sendMessage(setRecipient(user));
                    }}
                  />
                </Step>
              </div>
            }
          </>
        );
      case "point-select":
        return (
          <Box className={styles.pointSelectionBox}>
            <Step
              enabled={enabled}
              title={
                pointsProduct.url === "transfer-extra-points" ?
                  t("PURCHASE_POINTS.HOW_MANY_POINTS_TRANSFER") :
                  t("PURCHASE_POINTS.HOW_MANY_POINTS_PURCHASE")
              }
              subTitle={
                pointsProduct.url === "buy-basic-points" ?
                  <UserTierInfo customer={customer} customerData={customerData} /> :
                  subTitle
              }
              TooltipSlot={
                <Tooltip
                  direction="bottom"
                  title={t("PURCHASE_POINTS.SELECT_POINTS_TOOLTIP_TITLE")}
                  text={t("PURCHASE_POINTS.SELECT_POINTS_TOOLTIP_DESCRIPTION")}
                  width={400}
                >
                  <InfoIcon />
                </Tooltip>
              }
              open={openStep === step}
              setOpen={() => setOpenStep(step)}
            >
              <PointSelect
                footerTitle={t("PURCHASE_POINTS.YOU_WILL_RECEIVE")}
                points={transfer.points !== null ?
                  transfer.points :
                  pointsProduct.sasPointsToDeposit
                }
                stepSize={pointsProduct.sasPointsToDeposit}
                minValue={pointsProduct.sasPointsToDeposit}
                maxValue={maxValue}
                processing={transfer.state === "SETTING_POINTS"}
                onNext={(x: number) => {
                  const qty = Math.floor(x / pointsProduct.sasPointsToDeposit);
                  sendMessage(setTransferPoints(qty));
                }}
              />
            </Step>
          </Box>
        );
      case "payment-split":
        return (
          <Box>
            <Step
              enabled={enabled}
              title={t("PURCHASE_POINTS.CONFIRMATION")}
              subTitle={t("PURCHASE_POINTS.CONFIRMATION_SUBTITLE")}
              TooltipSlot={
                <Tooltip
                  direction="bottom"
                  title={t("PURCHASE_POINTS.PAYMENT_TOOLTIP_TITLE")}
                  text={t("PURCHASE_POINTS.PAYMENT_TOOLTIP_DESCRIPTION")}
                  width={400}
                >
                  <InfoIcon />
                </Tooltip>
              }
              open={openStep === step}
              setOpen={() => setOpenStep(step)}
            >
              <PaymentSplit
                points={pointsSplit}
                setPoints={x => {
                  sendMessage(setTransferPointsAmount(x));
                }}
                grandTotal={grandTotal.incVat}
                minValue={selectedPointPayment?.points.min.incVat || 0}
                maxValue={selectedPointPayment?.points.available.incVat || 0}
                processing={transfer.state !== "LOADED"}
              />
            </Step>
          </Box>
        );

      default:
        break;
    }
  };

  const stripeKey = transfer.data.payment?.code === STRIPE_PAYMENT_METHOD_CODE ?
    transfer.data.payment?.stripePublishableKey :
    "";

  const steps = getSteps(pointsProduct);

  return (
    <div className={styles.block}>
      <Header
        steps={steps}
        step={transfer.step}
        setStep={setOpenStep}
        purchaseFor={purchaseFor}
      />

      <StoreInfoContext.Provider value={{ ...storeInfo, info: { ...storeInfo.info, baseCurrencyCode: "SEK" } }}>
        <Wrapper>
          <header className={styles.header}>
            <h1>{pointsProduct.name}</h1>
            <p>{pointsProduct.description}</p>
          </header>

          <StripePaymentProvider
            client={transferClient}
            amountToPay={transfer.data.grandTotal.incVat}
            quoteCurrency={transfer.data.currencyCode}
            stripeKey={stripeKey || ""}
            confirmStripePaymentIntentQuery={confirmStripePaymentIntent}
            createStripePaymentIntentQuery={createStripePaymentIntent}
            storeInfo={storeInfo.info}
          >
            <OTPData.Provider>
              <CmsData.Provider url="terms">
                <div className={styles.container}>
                  <div className={styles.left}>
                    <div className={styles.outerBox}>
                      {steps.map((x, i) => (
                        <React.Fragment key={i}>
                          {stepComponents(steps[i])}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                  <div className={styles.right}>
                    {transfer.state !== "ERROR" &&
                      <Foldable open={errors.length === 0}>
                        <PaymentBox
                          pointsProduct={pointsProduct}
                          validationErrors={errors}
                        />
                      </Foldable>
                    }
                  </div>
                </div>
              </CmsData.Provider>
            </OTPData.Provider>
          </StripePaymentProvider>
        </Wrapper>
      </StoreInfoContext.Provider>
    </div>
  );
};

export default PurchasePointsFormView;
