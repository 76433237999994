/* @flow */

import type { Category, HintCategory } from "shop-state/types";

import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import Wrapper from "components/Wrapper";
import { useTranslate } from "@awardit/react-use-translate";
import styles from "./styles.scss";

const AdventCalendarHero = ({ category }: { category: Category | HintCategory }) => {
  const t = useTranslate();

  const totalCount = category.products ? category.products.totalCount : -1;

  return (
    <div className={styles.block}>
      <div className={styles.top}>
        <Wrapper>
          <Breadcrumbs
            className={styles.breadcrumbs}
            links={[{
              title: t("ADVENT_CALENDAR.TEXT"),
              pathname: `/${t("ADVENT_CALENDAR.CATEGORY_URL")}`,
            }]}
            current={category.name} />
        </Wrapper>

        <div className={styles.bannerSpacer} />

      </div>
      {totalCount >= 0 &&
        <Wrapper className={styles.totalCountWrapper}>
          <div className={styles.totalCount}>
            {totalCount === 0 && t("ADVENT_CALENDAR.TOTAL_COUNT_EMPTY")}
            {totalCount === 1 && t("ADVENT_CALENDAR.TOTAL_COUNT_SINGULAR")}
            {totalCount > 1 && t("ADVENT_CALENDAR.TOTAL_COUNT_PLURAL", { totalCount })}
          </div>
        </Wrapper>
      }
    </div>
  );
};

export default AdventCalendarHero;
