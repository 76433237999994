/* @flow */

import React, { useState, useContext } from "react";
import { StoreInfoContext } from "entrypoint/shared";
import { useTranslate } from "@awardit/react-use-translate";
import styles from "./styles.scss";
import { Button, Foldable } from "@crossroads/ui-components";
import useBrowserDimensions from "helpers/use-browser-dimensions";
import useBrowserLayoutEffect from "helpers/use-browser-layout-effect";
import { stringByLocale } from "./string-by-locale";

type SubscriptonStatus = {
  subscriptionStatus?: string,
};
type Props = {
  homeView?: boolean,
} & SubscriptonStatus;

const SubscriptionMessage = ({ subscriptionStatus }: SubscriptonStatus) => {
  const t = useTranslate();
  const { info: { locale } } = useContext(StoreInfoContext);
  const signupForm = stringByLocale(locale);
  switch (subscriptionStatus) {
    case "success":

      return (
        <div className={styles.header}>
          <h1>{t("NEWSLETTER.SUCCESS_TITLE")}</h1>
          <p>{t("NEWSLETTER.SUCCESS_BODY")}</p>
        </div>
      );

    case "error":

      return (
        <div className={styles.header}>
          <h1>{t("NEWSLETTER.ERROR_TITLE")}</h1>
          <p>{t("NEWSLETTER.ERROR_BODY")}</p>
          <br />
          <div dangerouslySetInnerHTML={{ __html: signupForm }} />
        </div>
      );

    case "finished":

      return (
        <div className={styles.header}>
          <h1>{t("NEWSLETTER.FINISHED_TITLE")}</h1>
        </div>
      );

    default:
      return null;
  }
};

const RegisterForm = ({ homeView, subscriptionStatus }: Props) => {
  const { info: { locale } } = useContext(StoreInfoContext);
  const { width: browserWidth } = useBrowserDimensions();
  const [isTiny, setIsTiny] = useState(false);
  const t = useTranslate();
  const [open, setOpen] = useState(false);
  const signupForm = stringByLocale(locale);

  useBrowserLayoutEffect(() => {
    if (browserWidth <= Number.parseInt(styles.tiny, 10)) {
      setIsTiny(true);
    }
    else {
      setIsTiny(false);
    }
  }, [browserWidth]);

  return (
    <div className={styles.block}>
      {subscriptionStatus === undefined ?
        (homeView === false ?
          <>
            <Button
              className={styles.openButton}
              onClick={() => setOpen(!open)}
            >
              {t("ADVENT_CALENDAR.FORM.SUBSCRIBE_NOW")}
            </Button>
            <Foldable open={open || !isTiny}>
              <div className={styles.header}>
                <h1>{t("ADVENT_CALENDAR.LANDING.HEADING")}</h1>
                <p>{t("ADVENT_CALENDAR.LANDING.TEXT")}</p>
                <p className={styles.desktop}>
                  {t("ADVENT_CALENDAR.FORM.SUBSCRIBE_NOW")}
                </p>
              </div>

              <div dangerouslySetInnerHTML={{ __html: signupForm }} />
            </Foldable>
          </> :
          <>
            <div className={styles.header}>
              <h1>{t("ADVENT_CALENDAR.LANDING.HEADING")}</h1>
              <p>{t("ADVENT_CALENDAR.LANDING.TEXT")}</p>
              <p className={styles.desktop}>
                {t("ADVENT_CALENDAR.FORM.SUBSCRIBE_NOW")}
              </p>
            </div>

            <div dangerouslySetInnerHTML={{ __html: signupForm }} />
          </>) : <SubscriptionMessage subscriptionStatus={subscriptionStatus} />

      }
    </div>
  );
};

export default RegisterForm;
