/* @flow */

import React, { useContext, useMemo } from "react";
import styles from "./styles.scss";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import Field, { SearchableDropdownField } from "components/Field";
import { useFormField } from "@awardit/formaggio";
import { Button } from "@crossroads/ui-components";

type Props = {
  type: "billing" | "shipping",
  className?: string,
  isInvalidCountry?: boolean,
  openCountryModal?: () => void,
};

type PrefilledCountryProps = {
  name: string,
  label: string,
  type: string,
  countries: $ReadOnlyArray<{ code: string, name: string }>,
  onLinkClicked: (e: Event) => void,
};

const PrefilledCountry = ({
  name,
  label,
  type,
  countries,
  onLinkClicked }: PrefilledCountryProps) => {
  const t = useTranslate();
  const { inputProps } = useFormField(name, "");

  const country = countries.find(c => c.code === inputProps.value);

  return (
    <div className={styles.prefilledCountryField}>

      <input
        disabled
        id={`${type}.country`}
        value={country?.name || ""}
        type="text"
      />

      <label
        htmlFor={`${type}.country`}
      >
        {label}
      </label>
      <div className={styles.prefilledCountryField__cta}>
        <Button
          variant="link"
          onClick={e => {
            e.preventDefault();
            onLinkClicked(e);
          }}
        >
          {t("ADDRESS.EDIT")}
        </Button>
      </div>
    </div>
  );
};

const InvalidCountry = ({
  type,
  label,
  onLinkClicked }: { type: string, label: string, onLinkClicked: (e: Event) => void }) => {
  const t = useTranslate();
  return (
    <div className={styles.prefilledCountryField}>
      <input
        disabled
        id={`${type}.country`}
        value=""
        type="text"
      />

      <label
        htmlFor={`${type}.country`}
      >
        {label}
      </label>
      <div className={cn(styles.prefilledCountryField__cta, styles.invalid)}>
        <span>
          {t("ADDRESS.NOT_YOUR_COUNTRY")}
        </span>
        &nbsp;
        <Button variant="link" onClick={onLinkClicked}>
          {t("ADDRESS.EDIT")}
        </Button>
      </div>
    </div>
  );
};

export const ContactInfo = () => {
  const t = useTranslate();

  return (
    <div className={styles.row}>
      <div className={styles.s50}>
        <Field alwaysValidate name="email" label={t("ACCOUNT.EMAIL")} />
      </div>
      <div className={styles.s50}>
        <Field alwaysValidate name="billing.telephone" label={t("ADDRESS.TELEPHONE")} />
      </div>
    </div>
  );
};

const Address = ({ type, className, isInvalidCountry, openCountryModal }: Props) => {
  const t = useTranslate();
  const { info: { countries, locale } } = useContext(StoreInfoContext);

  const sortedCountries = useMemo(() => {
    return countries.slice().sort((a, b) => {
      return a.name.localeCompare(
        b.name,
        locale.split("_")[0],
        { sensitivity: "base" }
      );
    });
  }, [countries, locale]);

  return (
    <div className={className}>
      <div className={styles.row}>
        <div className={styles.s50}>
          <Field alwaysValidate name={`${type}.firstname`} label={t("ADDRESS.FIRSTNAME")} variant="float" />
        </div>
        <div className={styles.s50}>
          <Field alwaysValidate name={`${type}.lastname`} label={t("ADDRESS.LASTNAME")} variant="float" />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.s50}>
          <Field alwaysValidate name={`${type}.street.0`} label={t("ADDRESS.STREET_1")} variant="float" />
        </div>
        <div className={styles.s50}>
          <Field alwaysValidate name={`${type}.street.1`} label={t("ADDRESS.STREET_2")} variant="float" />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.s33}>
          <Field alwaysValidate name={`${type}.postcode`} label={t("ADDRESS.POSTCODE")} variant="float" />
        </div>
        <div className={styles.s33}>
          <Field alwaysValidate name={`${type}.city`} label={t("ADDRESS.CITY")} variant="float" />
        </div>
        <div className={styles.s33}>
          {isInvalidCountry !== true && sortedCountries.length > 1 &&
            <SearchableDropdownField
              variant="countryDropdown"
              label={t("ADDRESS.COUNTRY")}
              className={styles.countries}
              name={`${type}.countryCode`}
              placeholder=""
              items={sortedCountries} />
          }
          {isInvalidCountry !== true && sortedCountries.length === 1 &&
            <PrefilledCountry
              type={type}
              label={t("ADDRESS.COUNTRY")}
              name={`${type}.countryCode`}
              countries={sortedCountries}
              onLinkClicked={openCountryModal ? openCountryModal : () => {}}
            />
          }
          {isInvalidCountry === true &&
            <InvalidCountry
              type={type}
              label={t("ADDRESS.COUNTRY")}
              name={`${type}.countryCode`}
              onLinkClicked={openCountryModal ? openCountryModal : () => {}}
            />
          }
        </div>
      </div>
    </div>
  );
};

export default Address;
